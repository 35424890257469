<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <Avatar size="large" class="primary" icon="pi pi-pencil" />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      Edit Programme
                    </div>
                    <div class="text-sm text-500">Modify programme record</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="md:col-9 sm:col-12 comp-grid">
            <div class="">
              <div>
                <template v-if="!loading">
                  <div class="grid">
                    <div class="col-12">
                      <form
                        ref="observer"
                        tag="form"
                        @submit.prevent="startRecordUpdate()"
                      >
                        <!--[form-content-start]-->
                        <div class="grid">
                    <div class="col-12 md:col-12">
                      <div class="mb-2 font-bold text-sm">
                        Programme Title *
                      </div>
                      <div id="ctrl-programme_title-holder">
                        <InputText
                          ref="ctrlprogramme_title"
                          v-model.trim="formData.programme_title"
                          label="Programme Title"
                          type="text"
                          placeholder="Enter Programme Title"
                          class="w-full"
                          :class="getErrorClass('programme_title')"
                        >
                        </InputText>
                      </div>
                    </div>
                    <div class="col-12 md:col-12">
                      <div class="mb-2 font-bold text-sm">Institution *</div>
                      <div class="">
                        <api-data-source
                          @loaded="
                            (response) =>
                              mapOptionField(response, 'caps_institution_id')
                          "
                          api-path="components_data/caps_institution_id_option_list"
                        >
                          <template v-slot="req">
                            <Dropdown
                              class="w-full"
                              :class="getErrorClass('caps_institution_id')"
                              :loading="req.loading"
                              optionLabel="label"
                              optionValue="value"
                              ref="ctrlcaps_institution_id"
                              v-model="formData.caps_institution_id"
                              :options="req.response"
                              label="Institution"
                              placeholder="Select ..."
                              :filter="true"
                            >
                            </Dropdown>
                          </template>
                        </api-data-source>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Programme Code </div>
                      <div id="ctrl-programme_code-holder">
                        <InputText
                          ref="ctrlprogramme_code"
                          v-model.trim="formData.programme_code"
                          label="Programme Code"
                          type="text"
                          placeholder="Programme Code"
                          class="w-full"
                          :class="getErrorClass('programme_code')"
                        >
                        </InputText>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Accreditation Duration </div>
                      <div id="ctrl-duration-holder">
                        <InputText
                          ref="ctrlduration"
                          v-model.trim="formData.duration"
                          label="Duration"
                          type="text"
                          placeholder="Example: 4 years"
                          class="w-full"
                          :class="getErrorClass('duration')"
                        >
                        </InputText>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Start Date</div>
                      <div id="ctrl-start_date-holder">
                        <Calendar
                          :showButtonBar="true"
                          class="w-full"
                          :class="getErrorClass('start_date')"
                          dateFormat="yy-mm-dd"
                          v-model="formData.start_date"
                          :showIcon="true"
                          mask="YYYY-MM-DD"
                          outlined
                          dense
                        />
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">End Date</div>
                      <div id="ctrl-end_date-holder">
                        <Calendar
                          :showButtonBar="true"
                          class="w-full"
                          :class="getErrorClass('end_date')"
                          dateFormat="yy-mm-dd"
                          v-model="formData.end_date"
                          :showIcon="true"
                          mask="YYYY-MM-DD"
                          outlined
                          dense
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="mb-2 font-bold text-sm">
                        Programme Description
                      </div>
                      <div id="ctrl-programme_description-holder">
                        <Textarea
                          :class="getErrorClass('programme_description')"
                          class="w-full"
                          ref="ctrlprogramme_description"
                          rows="2"
                          v-model="formData.programme_description"
                          placeholder="Programme Description"
                          type="textarea"
                        >
                        </Textarea>
                      </div>
                    </div>
                  </div>
                        <!--[form-content-end]-->
                        <div v-if="showSubmitButton" class="text-center my-3">
                          <Button
                            type="submit"
                            label="Update"
                            icon="pi pi-send"
                            :loading="saving"
                          />
                        </div>
                      </form>
                      <slot :submit="submit" :saving="saving"></slot>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="p-3 text-center">
                    <ProgressSpinner style="width: 50px; height: 50px" />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { PageMixin } from "../../mixins/page.js";
import { EditPageMixin } from "../../mixins/editpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  sameAs,
  minLength,
  maxLength,
  minValue,
  maxValue,
  numeric,
  integer,
  decimal,
  ipAddress,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "editCapsprogrammeslistPage",
  components: {},
  mixins: [PageMixin, EditPageMixin],
  props: {
    pageName: {
      type: String,
      default: "caps_programmes_list",
    },
    idName: {
      type: String,
      default: "caps_programme_id",
    },
    routeName: {
      type: String,
      default: "caps_programmes_listedit",
    },
    pagePath: {
      type: String,
      default: "caps_programmes_list/edit",
    },
    apiPath: {
      type: String,
      default: "caps_programmes_list/edit",
    },
  },
  data() {
    return {
      formData: {
        programme_title: "",
        programme_code: this.$utils.randomStr(5) + this.$utils.randomNum(5),
        caps_institution_id: "",
        duration: "",
        end_date: "",
        start_date: "",
        programme_description: "",
      },
      submitted: false,
      end_datePicker: false,
      start_datePicker: false,
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Edit Programme";
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["caps_programmes_list/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("caps_programmes_list/setCurrentRecord", value);
      },
    },
  },
  validations() {
    let formData = {
      programme_title: { required },
      programme_code: {},
      caps_institution_id: { required },
      duration: {},
      end_date: {},
      start_date: {},
      programme_description: {},
    };
    return { formData };
  },
  methods: {
    ...mapActions("caps_programmes_list", ["updateRecord", "fetchRecord"]),
    async startRecordUpdate() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      if (!isFormValid) {
        this.flashMsg("Please complete the form", "Form is invalid", "error");
        return;
      }
      this.saving = true;
      let id = this.id;
      let url = this.apiUrl;
      let payload = this.normalizedFormData();
      let data = { id, url, payload };
      this.updateRecord(data).then(
        (response) => {
          this.saving = false;
          this.flashMsg(this.msgAfterUpdate);
          this.resetForm();
          this.closeDialogs(); // close page dialog that if opened
          if (this.redirect) this.navigateTo(`/caps_programmes_list`);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    updateFormFields: function () {
      //update form fields value after load from api
      //e.g convert fieldvalue (value,value2,value2) to array
    },
    resetForm() {
      //reset form fields value
      this.formData = {
        programme_title: "",
        programme_code: this.$utils.randomChars(10),
        end_date: "",
        start_date: "",
        programme_description: "",
      };
      //raise event to reset other custom form components
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      if (this.v$.formData[field].$invalid && this.submitted) {
        return "p-invalid";
      }
      return "";
    },
  },
  watch: {},
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
